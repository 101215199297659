<template>
  <div
    class="LellyLoginView"
    :style="{
      'background-image': `url(${bg})`,
      'background-color': loginBgColor,
    }"
  >
    <div class="bg_mask" :style="backgroundStyle"></div>
    <div class="mask">
      <div :class="[boxTop == true ? 'boxTop' : 'box']">
        <div class="loading_box" ref="loading_box">
          <img :src="logo" alt="logo" class="logo" v-if="logo" />
          <LeliLogo v-if="mode == 'leli'" />

          <div class="loading_line">
            <div class="number">{{ this.color_line_vlaue }}%</div>
            <div
              class="color_line"
              ref="color_line"
              :style="{ 'background-color': themeColor }"
            >
              <!-- <div class="number">{{ this.color_line_vlaue }}%</div> -->
            </div>
          </div>
        </div>
        <div class="login_box" ref="login_box">
          <div class="title_box">
            <div class="title" :style="titleStyle">
              {{ $t(`${title}`) }}
            </div>
          </div>

          <!-- <div
            class="space_box"
            :style="{
              height: `${50}%`,
            }"
          ></div> -->

          <div class="input_box">
            <div class="item" :style="{ border: `1px ${themeColor} solid` }">
              <div class="icon-box" :style="{ 'background-color': themeColor }">
                <i
                  class="icon fas fa-user"
                  :style="{
                    'background-color': themeColor,
                    'box-shadow': `-4px 0 0 4px ${themeColor}`,
                    color: inputColor,
                  }"
                />
              </div>
              <input
                class="input"
                type="text"
                v-model="account"
                :placeholder="$t(`_accountPlaceholder`)"
                @keyup.enter="login()"
                :style="placeHolderStyle"
              />
            </div>
            <div class="item" :style="{ border: `1px ${themeColor} solid` }">
              <div class="icon-box" :style="{ 'background-color': themeColor }">
                <i
                  class="icon fas fa-lock"
                  :style="{
                    'background-color': themeColor,
                    'box-shadow': `-4px 0 0 4px ${themeColor}`,
                    color: inputColor,
                  }"
                />
              </div>
              <input
                class="input"
                type="password"
                v-model="password"
                :placeholder="$t(`_passwordPlaceholder`)"
                @keyup.enter="login()"
                :style="placeHolderStyle"
              />
            </div>
            <div
              class="btn"
              :style="{ 'background-color': themeColor, color: inputColor }"
              @click.prevent="login()"
            >
              <span class="text">
                {{ $t(`_login`) }}
              </span>
            </div>
          </div>

          <div>
            <el-radio-group v-model="play_type">
              <el-radio-button :label="2">{{ $t(`radio1`) }}</el-radio-button>
              <el-radio-button :label="1">{{ $t(`radio2`) }}</el-radio-button>
            </el-radio-group>
          </div>
          <div
            class="lan"
            :style="lanSelectBottom && { position: 'absolute', bottom: '64px' }"
          >
            <div
              class="lan_btn"
              v-for="(language, index) in languages_2"
              :key="index"
              :class="[{ select: language.name == currentLan }]"
              @click="selectLang(language.name)"
              :style="[
                language.name == currentLan
                  ? { 'box-shadow': `0 0 0px 1px ${themeColor}` }
                  : null,
              ]"
            >
              <div
                class="lan_img"
                :style="{ backgroundImage: `url(${language.img})` }"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mobile_lan" ref="mobile_lan">
      <div
        class="lan_btn"
        v-for="(language, index) in languages"
        :key="index"
        @click="index == 0 ? mobilelanFun() : selectLang(language.name)"
      >
        <div
          class="lan_img"
          :style="{ backgroundImage: `url(${language.img})` }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import md5 from "md5";
import { Loading } from "element-ui";
// // import { Base64 } from "js-base64";
import Cookies from "js-cookie";
import LeliLogo from "@/components/LeliLogo.vue";

export default {
  components: { LeliLogo },
  data() {
    return {
      mode: glob.mode,
      title: glob.title,
      loginBgColor: glob.loginBgColor,
      backgroundOpacity: glob.backgroundOpacity,
      showLogo: glob.showLogo,
      play_type: 1,
      languages: [
        {
          img: require("@/assets/images/FlagC/CN.svg"),
          name: "zhs",
        },
        {
          img: require("@/assets/images/FlagC/HK.svg"),
          name: "zht",
        },
        {
          img: require("@/assets/images/FlagC/US.svg"),
          name: "eng",
        },
        {
          img: require("@/assets/images/FlagC/KR.svg"),
          name: "ko",
        },
        {
          img: require("@/assets/images/FlagC/JP.svg"),
          name: "ja",
        },
      ],
      languages_2: [
        {
          img: require("@/assets/images/FlagC/CN.svg"),
          name: "zhs",
        },
        {
          img: require("@/assets/images/FlagC/HK.svg"),
          name: "zht",
        },
        {
          img: require("@/assets/images/FlagC/US.svg"),
          name: "eng",
        },
        {
          img: require("@/assets/images/FlagC/KR.svg"),
          name: "ko",
        },
        {
          img: require("@/assets/images/FlagC/JP.svg"),
          name: "ja",
        },
      ],
      currentLan: "zht",
      account: "",
      password: "",
      logo:
        glob.logo && glob.showLogo
          ? require(`@/assets/images/${glob.logo}`)
          : null,
      tokenLogo: glob.logo ? require(`@/assets/images/${glob.logo}`) : null,
      backgroundImg: glob.loginBgImage
        ? require(`@/assets/images/${glob.loginBgImage}`)
        : null,
      backgroundImg_phone: glob.loginBgImagePhone
        ? require(`@/assets/images/${glob.loginBgImagePhone}`)
        : null,
      boxTop: glob.loginBoxTop,
      color_line_vlaue: 0,
      themeColor: glob.themeColor,
      inputColor: glob.inputColor,
      inputPlaceHolder: glob.inputPlaceHolder,
      lanSelectBottom: glob.lanSelectBottom,
      titleLinearColor: glob.titleLinearColor,
    };
  },
  computed: {
    bg() {
      console.log(window.innerWidth);
      if (window.innerWidth > 500) {
        return this.backgroundImg;
      }
      return this.backgroundImg_phone;
    },
    placeHolderStyle() {
      return {
        "--placeholderColor": this.inputPlaceHolder,
      };
    },
    titleStyle() {
      if (this.titleLinearColor.length > 1) {
        let styleText = "";
        for (let i = 0; i < this.titleLinearColor.length; i++) {
          styleText += this.titleLinearColor[i];
          if (i + 1 < this.titleLinearColor.length) {
            styleText += ", ";
          }
        }
        return {
          "--titleLinearColor": `-webkit-linear-gradient(-90deg, ${styleText})`,
          "--titleColor": this.titleLinearColor[0],
          "-webkit-background-clip": "text",
          "-webkit-text-fill-color": "transparent",
        };
      }
      return {
        "--titleColor": this.titleLinearColor[0],
      };
    },
    backgroundStyle() {
      return {
        "--backgroundColor": `rgba(0, 0, 0, ${this.backgroundOpacity})`,
      };
    },
  },
  created() {},
  mounted() {
    this.runLoadingFun();

    var lan = localStorage.getItem("currentLan");

    if (!lan) {
      lan = this.currentLan;
    }

    let new_languages = [];
    for (let i = 0; i < this.languages.length; i++) {
      if (this.languages[i].name !== this.currentLan) {
        new_languages.push(this.languages[i]);
      } else {
        new_languages.unshift(this.languages[i]);
      }
    }
    this.languages = new_languages;

    this.$i18n.locale = lan;
    console.log("lan: ", lan);
    this.selectLang(lan);
    this.showLangs = false;
  },
  methods: {
    selectLang(lan) {
      // console.log(language, lan, index);

      let lanCookies = "zht";
      switch (lan) {
        case "eng":
          lanCookies = "eng";
          break;

        case "zht":
          lanCookies = "zht";
          break;

        case "zhs":
          lanCookies = "zhs";
          break;

        case "ja":
          lanCookies = "jpn";
          break;

        case "ko":
          lanCookies = "kor";
          break;

        default:
          lanCookies = "zht";
          break;
      }
      Cookies.set("lan", lanCookies);

      this.showLangs = !this.showLangs;
      if (lan === this.currentLan) {
        return;
      } else {
        localStorage.setItem("currentLan", lan);
        // localStorage.setItem("currentLanIdx", index);
        // localStorage.setItem("language", JSON.stringify(language));
        this.currentLan = lan;
        this.$i18n.locale = lan;

        let new_languages = [];
        for (let i = 0; i < this.languages.length; i++) {
          if (this.languages[i].name !== lan) {
            new_languages.push(this.languages[i]);
          } else {
            new_languages.unshift(this.languages[i]);
          }
        }
        this.languages = new_languages;
      }
      this.$nextTick(() => {
        this.$refs.mobile_lan.style.height = "40px";
      });
    },
    login() {
      const loadingInstance = Loading.service({
        lock: true,
        text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // 檢查帳號或密碼是否為空
      const loginParams = {
        account: this.account,
        passwd: md5(`${this.password}`),
        game: true,
      };

      if (!this.account.length || !this.password.length) {
        // this.$refs.form.classList.add("shake");
        // this.$refs.incorrect.classList.add("incorrect");
        loadingInstance.close();
        // this.incorrect = true;
        // setTimeout(() => {
        //   this.$refs.form.classList.remove("shake");
        // }, 1000);
        this.$message({
          // message: this.$t("_wrong"),
          message: this.$t("_empty"),
          type: "error",
          center: true,
        });
      } else {
        this.incorrect = false;
        // service.login(loginParams);

        // const windowReference = window.open();

        axios
          .post("/api/login", loginParams)
          .then((res) => {
            console.log(res);
            if (res.data.result == "success") {
              axios
                .get(`/api/bet360/oauth?mode=${this.play_type}`)
                .then((res) => {
                  console.log(res);
                  if (res.data.result == "success") {
                    // loadingInstance.close();
                    this.makeUrl(true, res.data.url);
                  } else {
                    loadingInstance.close();
                    if (res.data.msg == "Invalid target id") {
                      this.$message({
                        message: this.$t("_wrong"),
                        type: "error",
                        center: true,
                      });
                    } else if (res.data.msg == "Invalid account or password") {
                      this.$message({
                        message: this.$t("_wrong"),
                        type: "error",
                        center: true,
                      });
                    } else {
                      this.$message({
                        message: res.data.msg,
                        type: "error",
                        center: true,
                      });
                    }
                  }
                })
                .catch((err) => {
                  this.$message({
                    message: this.$t("_networkError"),
                    type: "error",
                    center: true,
                  });
                  loadingInstance.close();

                  console.log(err);
                });
            } else {
              loadingInstance.close();
              if (res.data.msg == "Invalid target id") {
                this.$message({
                  message: this.$t("_wrong"),
                  type: "error",
                  center: true,
                });
              } else if (res.data.msg == "Invalid account or password") {
                this.$message({
                  message: this.$t("_wrong"),
                  type: "error",
                  center: true,
                });
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                  center: true,
                });
              }
            }
          })
          .catch((err) => {
            if (err.response) {
              // console.log(error.response.data);
              // console.log(error.response.status);
              // console.log(error.response.headers);
              if (
                err.response.status === 403 &&
                err.response.data.msg === "Your ip is not in the whitelist"
              ) {
                this.$message({
                  message: this.$t("_ipNotInWhitelist"),
                  type: "error",
                  center: true,
                });
                // loadingInstance.close();
              } else {
                this.$message({
                  message: this.$t("_networkError"),
                  type: "error",
                  center: true,
                });
                loadingInstance.close();
              }
            }
            loadingInstance.close();
            console.log(err);
          });
      }
    },
    makeUrl(type, url) {
      // console.log("makeUrl", type, url);
      let jumpUrl = url;
      if (type) {
        jumpUrl += `&101token=${Cookies.get("token")}`;
      }
      jumpUrl += `&remove101token=${true}`;
      jumpUrl += `&101api=${glob.managebackendurl}`;
      jumpUrl += `&101domain=${window.location.href.split("#")[0]}`;

      jumpUrl += `&101logo=${
        window.location.href.split("#")[0]
      }${this.tokenLogo.slice(1)}`;
      jumpUrl += `&101title=${glob.gameTitle}`;
      if (glob.phoneMode) {
        jumpUrl += `&phoneMode=${glob.phoneMode}`;
      }
      if (glob.free) {
        jumpUrl += `&free=${glob.free}`;
      }

      // console.log(jumpUrl);
      window.location.replace(jumpUrl);
    },
    mobilelanFun() {
      if (this.$refs.mobile_lan.style.height === "40px") {
        this.$refs.mobile_lan.style.height = "fit-content";
      } else {
        this.$refs.mobile_lan.style.height = "40px";
      }
    },
    runLoadingFun() {
      console.log("runLoadingFun");
      setTimeout(() => {
        if (Math.floor(Math.random() * 3) !== 0) {
          this.color_line_vlaue += Math.floor(Math.random() * 50) + 1;
          // this.color_line_vlaue += 20;
        }
        if (this.color_line_vlaue < 100) {
          this.runLoadingFun();
        } else {
          this.color_line_vlaue = 100;
          setTimeout(() => {
            this.$refs.loading_box.style.opacity = `0`;
          }, 500);
          setTimeout(() => {
            this.$refs.loading_box.style.display = `none`;
            this.$refs.login_box.style.display = `block`;
          }, 1000);
          setTimeout(() => {
            this.$refs.login_box.style.transform = `translateY(0)`;
            this.$refs.login_box.style.opacity = `1`;
          }, 1100);
        }
        this.$refs.color_line.style.width = `${this.color_line_vlaue}%`;
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.LellyLoginView {
  width: 100vw;
  height: 100vh;
  // background-image: url("@/assets/images/Lelly/pc.png");
  background-position: 50% 10%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: end;
  color: #000;
  // position: relative;
  position: fixed;
  // bottom: 0;
  top: 0;
  overflow: hidden;
  .bg_mask {
    display: flex;
    height: 100vh;
    width: 100vw;
    z-index: 100;
    background-color: var(--backgroundColor);
  }
  .mask {
    z-index: 200;
    height: 50%;
    // height: 400px;
    width: 100vw;
    // width: 760px;
    display: flex;
    justify-content: center;
    align-items: center;
    // align-items: end;
    // overflow: hidden;
    position: fixed;
    bottom: 0;
    // background-color: #8882;
    // padding-bottom: 100px;
    .box {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .boxTop {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
    }
    .loading_box {
      color: #000;
      width: 100%;
      // height: 500px;
      // background-color: #8882;
      opacity: 1;
      transition: all 500ms ease-in-out;
      .logo {
        height: 150px;
        max-width: 100%;
        margin: 20px 0;
      }
      .loading_line {
        width: 80%;
        max-width: 700px;
        height: 20px;
        background-color: #8888;
        margin: 0 auto 20px;
        position: relative;
        border-radius: 50px;
        overflow: hidden;
        .color_line {
          height: 100%;
          width: 0%;
          background-color: #f9c900;
          border-radius: 50px;
          transition: all 500ms ease-in-out;
          z-index: 1;
          overflow: hidden;
          position: relative;
        }
        .number {
          position: absolute;
          width: 100%;
          height: 100%;
          line-height: 20px;
          left: 0;
          right: 0;
          top: 0;
          font-weight: bold;
          z-index: 3;
          color: #000;
        }
      }
    }
    .login_box {
      transition: all 500ms ease-in-out;
      display: none;
      // padding-top: 50vh;
      transform: translateY(50vh);
      opacity: 0;
      .title_box {
        // background-color: #0006;
        border-radius: 10px;
        width: fit-content;
        margin: 0 auto;
        padding: 0 10px;
        .title {
          font-size: 48px;
          font-weight: bold;
          margin: 20px 0 0;
          // text-shadow: 0px 2px 10px #000, 0px 0px 5px #000;
          // height: 64px;
          // line-height: 64px;
          background: var(--titleLinearColor);
          color: var(--titleColor);
        }
      }
      .input_box {
        margin: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .item {
          border: 1px #f9c900 solid;
          margin: 8px 0;
          border-radius: 5px;
          height: 48px;
          width: 300px;
          overflow: hidden;
          background-color: rgba(20, 20, 20, 0.8);
          display: flex;
          justify-content: center;
          align-items: center;
          .icon-box {
            width: 17%;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            .icon {
              background-color: #f9c900;
              font-size: 16px;
              box-shadow: -4px 0 0 4px #f9c900;
            }
          }
          .input {
            border: unset;
            background: unset;
            height: 46px;
            outline: none;
            font-size: 16px;
            padding: 0 15px;
            color: #fff;
            width: calc(100% - 48px);
            &::placeholder {
              color: var(--placeholderColor); // 动态值
            }
          }
          margin-right: 32px;
        }
        .btn {
          background-color: #f9c900;
          border-radius: 5px;
          height: 48px;
          width: 100px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          .text {
            font-size: 18px;
            line-height: 48px;
            font-weight: bold;
          }
        }
      }
      .lan {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .select {
          box-shadow: 0 0 0px 1px #f9c900;
        }
      }
    }
  }
  .mobile_lan {
    position: fixed;
    top: 10px;
    right: 10px;
    background: #0008;
    border-radius: 22px;
    height: 40px;
    overflow: hidden;
    display: none;
    z-index: 300;
  }
  .lan_btn {
    padding: 4px;
    margin: 4px;
    border-radius: 100px;
    cursor: pointer;
    .lan_img {
      background-position: center;
      background-size: cover;
      border-radius: 50%;
      padding: 13px;
    }
  }
}

@media screen and (max-width: 500px) {
  .LellyLoginView {
    // background-image: url("@/assets/images/Lelly/mobile.png");
    background-position: 50% 25%;
    // .login_box {
    //   .input_box {
    //     margin-top: 0 !important;
    //     .item {
    //       height: 32px !important;
    //       &:nth-child(1) {
    //         margin-right: unset !important;
    //       }
    //     }
    //     .btn {
    //       all: initial;
    //       height: 32px !important;
    //       width: 150px !important;
    //       .text {
    //         font-size: 14px !important;
    //       }
    //     }
    //   }
    // }
  }
  .LellyLoginView_phone {
    background-position: 50% 25%;
  }
}

@media screen and (max-width: 800px) {
  .LellyLoginView {
    // background-image: url("@/assets/images/Lelly/mobile.png");
    // background-position: 50% 25%;
    .mask {
      // height: 50% !important;
      height: 350px !important;
      align-items: end !important;
    }
    .title {
      font-size: 21px !important;
    }
    .login_box {
      .input_box {
        display: grid !important;
        justify-items: center;
        align-items: center;
        margin-top: 0 !important;
        .item {
          height: 32px !important;
          margin-right: unset !important;
        }
        .btn {
          all: initial;
          // margin: 10px auto !important;
          height: 32px !important;
          width: 150px !important;
          margin-top: 8px;
          .text {
            font-size: 14px !important;
          }
        }
      }
    }
    .lan {
      display: none !important;
    }
    .mobile_lan {
      display: block !important;
      width: 40px;
      height: 40px;
    }
  }
}
</style>
